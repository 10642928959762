import React from "react";
import ReactDOM from "react-dom";
import mapboxgl from "mapbox-gl";
import Card from "./Card";
import * as http from "https";
import { sign } from "crypto";
import { signMap } from "./signs";

/*
advertising=poster_box
animated=screen
lit=yes
operator=QMS
operator:website=https://www.qmsmedia.com/
sides=2
support=ground
*/

interface TrafficSignInterface {
    traffic_sign: string;
    note?: string;
    mapillary?: string;
    direction?: string;
}
export interface RawOverpassResponse {
    type: "node";
    id: number;
    lat: number;
    lon: number;
    tags: TrafficSignInterface;
}
export interface CleanedOverpassResponse extends RawOverpassResponse {
    // Extra tags
}

export function processOsmData(
    input: RawOverpassResponse[]
): CleanedOverpassResponse[] {
    return (
        input
            // @ts-ignore
            .filter((item) => item.tags.advertising !== "no")
            .map((cafe) => {
                return {
                    ...cafe,
                };
            })
    );
}

const cityOfSydneyRequestString = `

[out:json][timeout:25];
rel(1251066);map_to_area->.region;
(
    node(area.region)["traffic_sign"];
);
out body;
>;
out skel qt;
`;

// southern-most latitude, western-most longitude, northern-most latitude, eastern-most longitude.
export async function getOSMData(
    bounds: number[]
): Promise<RawOverpassResponse[]> {
    const options = {
        hostname: "overpass-api.de",
        port: 443,
        path: "/api/interpreter",
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
    };
    console.log("Started POST request...");
    const boundsStr = bounds.join(",");
    const boundsRequestStr = `
    [out:json][timeout:25];
    (
        node["traffic_sign"](${boundsStr});
    );
    out body;
    >;
    out skel qt;
    `;
    const overpassRequestStr = cityOfSydneyRequestString

    return new Promise((resolve, reject) => {
        var req = http.request(options, function (res) {
            var body = "";
            res.setEncoding("utf8");
            res.on("data", (chunk) => (body += chunk));
            res.on("end", function () {
                if (res.statusCode !== 200) {
                    console.log("error code", res.statusCode);
                    reject(res.statusCode);
                }

                const jsonResponse = JSON.parse(body);
                const bars: RawOverpassResponse[] = jsonResponse.elements;
                resolve(bars);
            });
        });
        req.on("error", function (e) {
            reject(e.message);
        });
        req.write(overpassRequestStr);
        req.end();
    });
}

/** OSM Direction is the way the sign is *facing* */
function osmDirectionToRotation(direction: string | undefined): number {
    if (direction === undefined) {
        return 0;
    }
    if (direction === 'S') {
        return 0;
    }
    if (direction === 'SW') {
        return 45;
    }
    if (direction === 'W') {
        return 90;
    }
    if (direction === 'NW') {
        return 135;
    }
    if (direction === 'N') {
        return 180;
    }
    if (direction === 'NE') {
        return 225;
    }
    if (direction === 'E') {
        return 270;
    }
    if (direction === 'SE') {
        return 315;
    }
    // If direction is an integer, return it as a number
    const directionAsNumber = parseInt(direction);
    if (!isNaN(directionAsNumber)) {
        return 360 - directionAsNumber;
    }
    console.error(`Unknown direction ${direction}`);
    return 0;
}

const globalMissingSignCounter: Record<string, number> = {};
export function drawMarker(
    item: CleanedOverpassResponse,
    map: mapboxgl.Map
): mapboxgl.Marker {
    const { lat, lon } = item;

    const placeholder = document.createElement("div");
    ReactDOM.render(<Card item={item} />, placeholder);

    var popup = new mapboxgl.Popup({
        anchor: "bottom", // make the popup appear above the pin
    }).setDOMContent(placeholder);

    const el = document.createElement('div');
    el.className = 'marker';
    el.id = `sign-${item.tags.traffic_sign}`;

    const baseWidth = 80;
    const aspectRatio = 2;
    const height = baseWidth * aspectRatio;

    let signsInNode: string[];
    // Todo, support signs not just next to or above each other (eg. 2x above each other, one next to)
    if (item.tags.traffic_sign.includes(';')) {
        signsInNode = item.tags.traffic_sign.split(";");
        el.style.display = 'flex'; // Display images side by side
        el.style.flexDirection = 'row';
    } else if (item.tags.traffic_sign.includes(',')) {
        signsInNode = item.tags.traffic_sign.split(",");
        el.style.display = 'flex';
        el.style.flexDirection = 'column'; // Display images in a vertical line
    } else {
        signsInNode = [item.tags.traffic_sign]; // Single sign, treated as an array for consistency
    }
    const addAuPrefixIfMissing = (lowercaseSignCode: string) => signMap[`au:${lowercaseSignCode}`]
        ? `au:${lowercaseSignCode}`
        : lowercaseSignCode;
    

    for (const sign of signsInNode) {
        const lowercaseSignCode = sign.toLowerCase().trim();
        // If the sign code is only in our map with the australian prefix, add that prefix
        const normalisedLowercaseSignCode = addAuPrefixIfMissing(lowercaseSignCode.replace('au:nsw', 'au'));

        if (signMap[normalisedLowercaseSignCode]) {
            const img = document.createElement('img');
            img.className = "sign-image";
            img.src = signMap[normalisedLowercaseSignCode];
            el.appendChild(img);
        } else {
            const img = document.createElement('img');
            img.className = "sign-image";
            img.src = '/img/unknown-sign.png';
            el.appendChild(img);
            console.error(`No image found for sign ${normalisedLowercaseSignCode} (from ${sign.trim()})`);
            // Increment the counter for this sign code. If it's the first time we've seen it, set it to 1
            globalMissingSignCounter[normalisedLowercaseSignCode] = (globalMissingSignCounter[normalisedLowercaseSignCode] || 0) + 1;
        }
    }

    // Dynamically adjust the size and styling based on the number and arrangement of signs
    // el.style.width = `${baseWidth}px`;
    // el.style.height = `${height * signsInNode.length}px`; // Adjust height based on the number of images for vertical layout
    el.style.backgroundSize = '100%';
    el.style.backgroundPosition = 'center center';
    el.style.backgroundRepeat = "no-repeat";

    let markerOptions: mapboxgl.MarkerOptions = {};
    markerOptions.scale = 0.7;

    const marker = new mapboxgl.Marker(el)
        .setRotation(osmDirectionToRotation(item.tags.direction))
        .setRotationAlignment(item.tags.direction !== undefined ? 'map' : 'viewport')
        .setLngLat([lon, lat])
        .setPopup(popup) // sets a popup on this marker
        .addTo(map);


    return marker;
}

export function drawmap(map: mapboxgl.Map): void {
    map.addControl(new mapboxgl.NavigationControl());
    map.addControl(new mapboxgl.FullscreenControl());
    // Add geolocate control to the map.
    map.addControl(
        new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true,
            },
            trackUserLocation: true,
        })
    );

    map.on("moveend", function (originalEvent) {
        const { lat, lng } = map.getCenter();
        console.log("A moveend event occurred.");
        console.log({ lat, lng });

        // eg https://localhost:3000
        const location = window.location.origin;
        console.log({ location });
    });
}
export function removeMarkers(markers: mapboxgl.Marker[]): void {
    markers.map((marker) => marker.remove());
}

export function drawMarkers(
    map: mapboxgl.Map,
    points: CleanedOverpassResponse[]
): mapboxgl.Marker[] {
    const markers = points
        .filter((point) => point.lat && point.lon)
        .map((node: CleanedOverpassResponse) => {
            return drawMarker(node, map);
        });

    function zoomToWidth(zoom: number) {
        const newWidth = 15 * Math.max(zoom - 15, 0) + 18;
        return newWidth
    }

    // Function to adjust marker size based on zoom level
    function adjustMarkerSize() {
        const currentZoom = map.getZoom();
        const newWidth = zoomToWidth(currentZoom);
        // Adjust size based on zoom level; you might need to tweak the formula depending on desired effect
        // console.log(`adjusting marker size at zoom ${currentZoom}, new width ${newWidth}`);
        document.documentElement.style.setProperty('--sign-image-width', `${newWidth}px`);
    }
    // Initial size adjustment to ensure marker starts at correct size
    adjustMarkerSize();

    // Add zoom event listener to adjust marker size when map zoom changes
    map.on('zoom', adjustMarkerSize);

    console.log(`Missing sign codes: ${JSON.stringify(globalMissingSignCounter, null, 2)}`);

    return markers;
}
