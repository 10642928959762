
// See
// https://www.transport.nsw.gov.au/operations/roads-and-waterways/traffic-signs
// const nswImgUrl = (sign: string) =>
//     `https://www.transport.nsw.gov.au/sites/default/files/styles/content_desktop_2x/public/media/images/rww/business-industry/partners-suppliers/signage/trafficsigns/images/${sign}.gif`;

const nswImgUrl = (sign: string) =>
  `/img/nsw/${sign}.gif`;

/** Map of lowercase sign names to sign urls */
export const signMap: Record<string, string> = {
  "au:r2-6n(r)": "/img/wikimedia/241px-Australia_road_sign_R2-6N-R.svg.png",
  "au:r2-4n": "/img/wikimedia/289px-Australia_road_sign_R2-4N.svg.png",
  "au:w6-v104": "/img/wikimedia/481px-AU-VIC_road_sign_W6-V104.svg.png",
  "au:r9-203-3n": nswImgUrl('r9-203-3n'),
  "au:r9-4": nswImgUrl('r9-4'),
  "au:g9-58": nswImgUrl('g9-58'),
  "au:w8-8": nswImgUrl('w8-8'),
  "au:g9-390n": nswImgUrl("g9-390n"),
  "au:g9-390n[20]": nswImgUrl("g9-390n"),
  "au:r6-6": nswImgUrl("r6-6"),
  "au:w6-2": nswImgUrl("w6-2"),
  "au:r7-10-1n": nswImgUrl("r7-10-1n"),
  "au:g9-390n[15]": '/img/custom/g9-390n[15].png',
  "au:g9-390n[10]": '/img/custom/g9-390n[10].png',
  "au:g9-390n[25]": '/img/custom/g9-390n[25].png',
  "au:g9-390n[30]": '/img/custom/g9-390n[30].png',
  "au:g9-390n[40]": '/img/custom/g9-390n[40].png',
  "au:r7-4": nswImgUrl("r7-4"),
  "au:r7-3(r)": nswImgUrl("r7-3_r"),
  "au:r4-4": nswImgUrl("r4-4"),
  "au:g9-18": nswImgUrl("g9-18"),
  "au:g9-19n": nswImgUrl("g9-19n"),
  "au:w6-10": nswImgUrl("w6-10"),
  "au:r2-10": nswImgUrl("r2-10"),
  "au:r6-15": nswImgUrl("r6-15"),
  "au:r1-1": nswImgUrl("r1-1"),
  "au:r8-2": nswImgUrl("r8-2"),
  "au:r5-65": nswImgUrl("r5-65"),
  "au:r1-2": nswImgUrl("r1-2"),
  "au:r4-1": nswImgUrl("r4-1"),
  "au:w2-7": nswImgUrl("w2-7"),
  // Informal names
  "give_way": nswImgUrl("r1-2"),
  "maxspeed": nswImgUrl("r4-1"),
  "roundabout": nswImgUrl("w2-7"),
};